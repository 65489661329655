const initialState = {
  timezone: "",
};

export default (state = initialState, { type, timezone }) => {
  switch (type) {
    case "TIMEZONE:SET":
      return {
        ...state,
        timezone: timezone,
      };

    default:
      return state;
  }
};
