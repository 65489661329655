import { BaseAction } from "../actions";

export type AppStore = {
  authModalVisible: boolean;
  deliveryModalVisible: boolean;
  lotteryModalVisible: boolean;
};

export enum AppTypes {
  APP_SET_AUTH_VISIBLE = "APP:SET_AUTH_VISIBLE",
  APP_SET_DELIVERY_MODAL_VISIBLE = "APP:SET_DELIVERY_MODAL_VISIBLE",
  APP_SET_LOTTERY_MODAL_VISIBLE = "APP:SET_LOTTERY_MODAL_VISIBLE",
  APP_DATA_LOADED_START = "APP:DATA_LOADED_START",
  APP_DATA_LOADED_DONE = "APP:DATA_LOADED_DONE",
}

const initialState: AppStore = {
  authModalVisible: false,
  deliveryModalVisible: false,
  lotteryModalVisible: false,
};

type AppActions =
  | BaseAction<AppTypes.APP_SET_AUTH_VISIBLE, boolean>
  | BaseAction<AppTypes.APP_SET_DELIVERY_MODAL_VISIBLE, boolean>;

export default (state = initialState, action: AppActions): AppStore => {
  switch (action.type) {
    case AppTypes.APP_SET_AUTH_VISIBLE:
      return {
        ...state,
        authModalVisible: action.payload,
      };
    case AppTypes.APP_SET_DELIVERY_MODAL_VISIBLE:
      return {
        ...state,
        deliveryModalVisible: action.payload,
      };

    default:
      return state;
  }
};
