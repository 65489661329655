import { LatLng, IShop } from "./../../../components/Basket/Order";
type MapStore = {
  center: LatLng;
  zoom?: number;
  coord: LatLng;
  region: LatLng;
  shops: IShop[];
  point: any;
};

type BaseAction = {
  type: string;
  payload: MapStore;
};

export enum MapTypes {
  MAP_SET_COORD = "MAP:SET_COORD",
}

const initialState: MapStore = {
  center: [],
  coord: [],
  shops: [],
  region: [],
  point: {},
};

export default (state = initialState, { type, payload }: BaseAction) => {
  switch (type) {
    case MapTypes.MAP_SET_COORD:
      return {
        ...state,
        center: payload.center,
        coord: payload.coord,
        shops: payload.shops,
        region: payload.region,
        point: payload.point,
      };
    default:
      return state;
  }
};
