const initialState = {
  items: [],
  filteredItems: [],
  searchQuery: "",
  modificators: [],
  isHidden: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "FOODS:SET_ITEMS":
      return {
        ...state,
        items: payload,
      };
    case "FOODS:SEARCH":
      return {
        ...state,
        filteredItems: payload,
      };
    case "FOODS:SET_SEARCH_QUERY":
      return {
        ...state,
        searchQuery: payload,
      };
    case "FOODS:SET_MODIFICATORS":
      return {
        ...state,
        modificators: payload,
      };
    case "FOODS:SET_HIDDEN":
      return {
        ...state,
        isHidden: !state.isHidden,
      };
    default:
      return state;
  }
};
