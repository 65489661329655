import { DefaultAction } from "../actions";

type DelivetyState = {
  address: object;
  result: boolean;
  minTotal: number;
  freeDeliveryPrice: number;
  freeDeliveryPriceHC: number;
};

const initialState: DelivetyState = {
  address: {},
  result: true,
  minTotal: 0,
  freeDeliveryPrice: 0,
  freeDeliveryPriceHC: 0,
};

export enum DeliveryActionNames {
  DELIVERY_FLUSH = "DELIVERY:FLUSH",
  DELIVERY_SET_VALIDATE_ADDRESS = "DELIVERY:SET_VALIDATE_ADDRESS",
  DELIVERY_SET_VALIDATE_ZONE = "DELIVERY:SET_VALIDATE_ZONE",
  DELIVERY_SET_MIN_PRICE = "DELIVERY:SET_MIN_PRICE",
  DELIVERY_SET_FREE_DELIVERY_PRICE = "DELIVERY:SET_FREE_DELIVERY_PRICE",
}

export default (
  state = initialState,
  { type, payload }: DefaultAction<any>
): DelivetyState => {
  switch (type) {
    case DeliveryActionNames.DELIVERY_FLUSH: {
      return { ...initialState };
    }
    case DeliveryActionNames.DELIVERY_SET_VALIDATE_ADDRESS:
      return {
        ...state,
        address: payload,
      };
    case DeliveryActionNames.DELIVERY_SET_VALIDATE_ZONE:
      return {
        ...state,
        result: payload,
      };
    case DeliveryActionNames.DELIVERY_SET_MIN_PRICE: {
      return {
        ...state,
        minTotal: payload,
      };
    }
    case DeliveryActionNames.DELIVERY_SET_FREE_DELIVERY_PRICE: {
      return {
        ...state,
        freeDeliveryPrice: payload,
      };
    }
    default:
      return state;
  }
};
