export const initialState = {
  user: {
    name: null,
    phone: null,
    bonuses: null,
    customer_id: null,
    address: null,
    address_name: null,
    token: null,
    block: null,
  },
  addresses: [],
  orders: [],
  active_orders: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "USER:SET":
      return {
        ...state,
        user: payload,
      };
    case "USER:CLEAR":
      return {
        ...state,
        user: initialState.user,
      };
    case "USER:SET_ORDERS":
      return {
        ...state,
        orders: payload,
      };
    case "USER:SET_ACIVE_ORDERS":
      return {
        ...state,
        active_orders: payload,
      };
    case "USER:SET_ADDRESSES":
      return {
        ...state,
        addresses: payload,
      };

    default:
      return state;
  }
};
