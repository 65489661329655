import _ from "lodash";
import cookie from "react-cookies";

const initialState = {
  cities: [],
  selected_city: {
    id: null,
    name: null,
    phone_prefix: null,
    currency: null,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "CITY:SET_CITIES":
      let _state = {
        ...state,
        cities: payload.data,
      };

      const selected_city_cookie = cookie.load("cityId");
      if (selected_city_cookie !== null && selected_city_cookie !== undefined) {
        const city = _.find(
          payload.data,
          (city) => parseInt(city.id) === parseInt(selected_city_cookie)
        );

        if (city !== null) {
          return {
            ..._state,
            selected_city: city,
          };
        }
      }
      return _state;
    case "CITY:SELECT_CITY":
      return {
        ...state,
        selected_city: payload,
      };

    default:
      return state;
  }
};
