import { PaymentTypes } from "@components/Basket/Order";
import cookie from "react-cookies";
import { DefaultAction } from "../actions";
import moment from "moment";

export type CartState = ReturnType<typeof createInitState>;

const createInitState = () => ({
  order: {
    active_order: 0,
    username: "",
    phone: "",
    city: "",
    address: cookie.load("delivery-address") || "",
    point: {
      id: 0,
      name: "",
      address: "",
      working_time_start: "",
      working_time_end: "",
      hc: false,
      city: 0,
      lat: 0,
      lon: 0,
      geometry: {
        lat: 0,
        lng: 0,
      },
    },
    apartment: cookie.load("delivery-address-apartment") || "",
    porch: cookie.load("delivery-address-porch") || "",
    floor: cookie.load("delivery-address-floor") || "",
    delivery_type: cookie.load("delivery-type") || "takeout",
    pay_type: "online" as PaymentTypes,
    confirmed_pay: false,
    pay_change: 0,
    additional_cutlery: 0,
    selected_discount: null,
    comment: "",
    intercom: false,
    bonuses: 0,
    private_property: false,
    custom_time: true,
    to_datetime: moment(),
  },
  items: [],
  originalItems: [],
  total: 0,
});

const initialState: CartState = createInitState();

export default (
  state = initialState,
  { type, payload }: DefaultAction<any>
) => {
  switch (type) {
    case "CART:SET_TOTAL":
      return {
        ...state,
        total: payload,
      };
    case "CART:SET_ITEMS":
      return {
        ...state,
        items: payload,
        originalItems: payload,
      };
    case "CART:SET_USER":
      return {
        ...state,
        order: {
          ...state.order,
          username: payload,
        },
      };
    case "CART:SET_PHONE":
      return {
        ...state,
        order: {
          ...state.order,
          phone: payload,
        },
      };
    case "CART:SET_CITY":
      return {
        ...state,
        order: {
          ...state.order,
          city: payload,
        },
      };
    case "CART:SET_ADDRESS":
      return {
        ...state,
        order: {
          ...state.order,
          address: payload,
        },
      };
    case "CART:SET_POINT":
      return {
        ...state,
        order: {
          ...state.order,
          point: payload,
        },
      };
    case "CART:SET_PRIVATE_PROPERTY":
      return {
        ...state,
        order: {
          ...state.order,
          private_property: payload,
        },
      };
    case "CART:SET_CUSTOM_TIME":
      return {
        ...state,
        order: {
          ...state.order,
          custom_time: payload,
        },
      };
    case "CART:SET_TO_DATETIME":
      return {
        ...state,
        order: {
          ...state.order,
          to_datetime: payload,
        },
      };
    case "CART:SET_APARTMENT":
      return {
        ...state,
        order: {
          ...state.order,
          apartment: payload,
        },
      };
    case "CART:SET_PORCH":
      return {
        ...state,
        order: {
          ...state.order,
          porch: payload,
        },
      };
    case "CART:SET_FLOOR":
      return {
        ...state,
        order: {
          ...state.order,
          floor: payload,
        },
      };
    case "CART:SET_DELIVERY_TYPE":
      return {
        ...state,
        order: {
          ...state.order,
          delivery_type: payload,
        },
      };
    case "CART:SET_PAY_TYPE":
      return {
        ...state,
        order: {
          ...state.order,
          pay_type: payload,
        },
      };
    case "CART:SET_PAY_CHANGE":
      return {
        ...state,
        order: {
          ...state.order,
          pay_change: payload,
        },
      };
    case "CART:SET_ADDITIONAL_CUTLERY":
      return {
        ...state,
        order: {
          ...state.order,
          additional_cutlery: payload,
        },
      };
    case "CART:SET_SELECTED_DISCOUNT":
      return {
        ...state,
        order: {
          ...state.order,
          selected_discount: payload
        }
      };
    case "CART:SET_COMMENT":
      return {
        ...state,
        order: {
          ...state.order,
          comment: payload,
        },
      };
    case "CART:CONFIRM_PAY":
      return {
        ...state,
        order: {
          ...state.order,
          confirmed_pay: payload,
        },
      };
    case "CART:SET_INTERCOM":
      return {
        ...state,
        order: {
          ...state.order,
          intercom: payload,
        },
      };
    case "CART:SET_BONUSES":
      return {
        ...state,
        order: {
          ...state.order,
          bonuses: payload,
        },
      };
    case "CART:CLEAR":
      return initialState;
    case "CART:SET_ACTIVE_ORDER":
      return {
        ...state,
        order: {
          ...state.order,
          active_order: payload,
        },
      };
    default:
      return state;
  }
};
