const initialState = {
  promocode: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "PROMOCODE:SET_PROMOCODE":
      return {
        ...state,
        promocode: payload,
      };
    default:
      return state;
  }
};
