import cookie from "react-cookies";

const initialState = {
  items: [],
  currentPos: cookie.load("position") || {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "POS:SET_ITEMS":
      return {
        ...state,
        items: payload,
      };
    case "POS:SET_POS":
      return {
        ...state,
        currentPos: payload,
      };
    default:
      return state;
  }
};
