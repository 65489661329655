import { combineReducers } from "redux";
import appReducer, { AppStore } from "./app/appReducer";
import cartReducer, { CartState } from "./cart/cartReducer";
import categoriesReducer from "./categories/categoriesReducer";
import cityReducer from "./city/cityReducer";
import deliveryReducer from "./delivery/deliveryReducer";
import filterReducer from "./filter/filterReducer";
import foodReducer from "./food/foodReducer";
import mapReducer from "./map/mapReducer";
import positionReducer from "./position/positionReducer";
import preloaderReducer from "./preloader/preloaderReducer";
import timezoneReducer from "./timezone/timezoneReducer";
import userReducer from "./user/userReducer";
import promocodeReducer from "./promocode/promocodeReducer";

export type GlobalState = {
  categories: any;
  cart: CartState;
  food: any;
  user: any;
  filter: any;
  preloader: any;
  city: any;
  position: any;
  timezone: any;
  delivery: any;
  app: AppStore;
  map: any;
  promocode: any;
};

export default combineReducers<GlobalState>({
  categories: categoriesReducer,
  cart: cartReducer,
  food: foodReducer,
  user: userReducer,
  filter: filterReducer,
  preloader: preloaderReducer,
  city: cityReducer,
  position: positionReducer,
  timezone: timezoneReducer,
  delivery: deliveryReducer,
  app: appReducer,
  map: mapReducer,
  promocode: promocodeReducer,
});
